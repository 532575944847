import { useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { useConnectWalletCallback } from 'state/wallet/hooks'
import styled, { keyframes } from 'styled-components/macro'
import { Wallet } from './ConnectModal'
import { ConnectorNames } from 'connectors'

const StyledButton = styled.button<{ recommended?: boolean }>`
  margin-bottom: 1rem;
  background: ${({ recommended }) => (recommended ? '#ecb049' : '#f7f7f7')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 15px;
  padding: 1.5rem 2rem;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: ${({ disabled }) => (disabled ? '#999' : '#000')};
  text-align: left;

  &:last-child {
    margin: 0;
  }

  img {
    width: 35px;
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  }

  &:hover {
    background: ${({ disabled }) => (disabled ? '#f7f7f7' : 'rgba(0, 0, 255, 0.4)')};
  }
`
const CommingSoon = styled.span`
  display: block;
  font-size: 12px;
`
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const Spinner = styled(CgSpinner)`
  animation: ${spin} 0.75s linear infinite;
  margin-right: 0.5rem;
`

function WalletButton({ wallet, onDismiss }: { wallet: Wallet; onDismiss: () => void }): JSX.Element {
  const connectWalletCallback = useConnectWalletCallback()
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    if (wallet.link) {
      switch (wallet.connector) {
        case ConnectorNames.CasperWallet:
          // @ts-ignore
          if (window.CasperWalletProvider === undefined) {
            window.open(wallet.link)
          }
          break
        case ConnectorNames.CasperSigner:
          if (window.casperlabsHelper === undefined) {
            window.open(wallet.link)
          }
          break
        case ConnectorNames.CasperDash:
          // @ts-ignore
          if (window.casperDashHelper === undefined) {
            window.open(wallet.link)
          }
          break
        default:
          break
      }
    }
    setLoading(true)
    onDismiss()
    await connectWalletCallback(wallet.connector)
  }

  return (
    <StyledButton recommended={wallet.recommended} onClick={onClick}>
      <span>
        {loading && <Spinner />}
        {wallet.name}
        {wallet.recommended && <CommingSoon>Recommended</CommingSoon>}
      </span>
      <img src={wallet.image} alt="" />
    </StyledButton>
  )
}

export default WalletButton
