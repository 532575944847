import { useCallback } from 'react'
import axios from 'axios'

import { CLPublicKey } from 'casper-js-sdk'
import { useCurrentNetwork } from './useNetwork'

export const useBids = (account: string | undefined | null): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      if (account) {
        const _accountHash = CLPublicKey.fromHex(account).toAccountHashStr()
        const accountHash = _accountHash.split('-')[2]
        const response = await axios.get(`${currentNetwork?.urlApi}/getbid?bidder=${accountHash}&isActive=true`)
        if (response.status === 200 && response.data.bid) {
          return response.data.bid
        }
      }
    } catch (error: any) {
      console.error(error)
      return []
    }
  }, [account])
}

export const useTopBid = (tokenId: string, contractHash: string): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      if (tokenId && contractHash) {
        const response = await axios.get(
          `${currentNetwork?.urlApi}/getbid?tokenId=${tokenId}&nftContract=${contractHash}&isActive=true`,
        )
        if (response.status === 200 && response.data.topBid) {
          return response.data.topBid
        }
      }
    } catch (error: any) {
      console.error(error)
      return []
    }
  }, [tokenId, contractHash])
}

export const useBid = (tokenId: string, contractHash: string): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      if (tokenId && contractHash) {
        const response = await axios.get(
          `${currentNetwork?.urlApi}/getbid?nftContract=${contractHash}&isActive=true&tokenId=${tokenId}`,
        )
        if (response.status === 200 && response.data.bid) {
          return response.data.bid
        }
      }
    } catch (error: any) {
      console.error(error)
      return []
    }
  }, [tokenId, contractHash])
}
