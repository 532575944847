import { useCallback } from 'react'
import axios from 'axios'
import { useCurrentNetwork } from './useNetwork'

export const useNFTs = (accountHash: string | undefined | null): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      if (accountHash) {
        const response = await axios.get(
          `${currentNetwork?.apiCSPRLive}/accounts/${accountHash}/nft-tokens?page=1&limit=1000`,
        )

        if (response.status === 200 && response.data) {
          const _res = response.data.data
          return _res.filter(box => box.contract_package_hash === currentNetwork?.contract?.BoxPackageHash)
        }
      }
    } catch (error: any) {
      console.error(error)
      return []
    }
  }, [accountHash])
}
