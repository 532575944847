import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import { CasperClient, CLPublicKey, CLValueBuilder, decodeBase16, DeployUtil, RuntimeArgs } from 'casper-js-sdk'
import { useBid, useCurrentNetwork, useTopBid } from 'hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import Modal from 'components/Modal'
import Button from 'components/Button'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import BigNumber from 'bignumber.js'
import { priceBid } from '../../utils'
import axios from 'axios'

const StyledModal = styled(Modal)`
  .modal-body {
    padding: 0 2.5rem 3.75rem;
  }
`

const InputWrapper = styled.div`
  margin-bottom: 2rem;
`

const ModalSubTitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  color: #191820;
  margin-bottom: 2rem;
  margin-top: 1rem;
  text-align: center;
`

const InputField = styled.fieldset`
  position: relative;
  margin-bottom: 1rem;

  label {
    position: absolute;
    font-weight: 100;
    font-size: 14px;
    line-height: 22px;
    right: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    border: 1px solid #e5e5e5;
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    border-radius: 24px;
    padding: 11px 21px;

    &:focus {
      outline: none !important;
      border-color: #b9b8bb;
    }
  }
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #565660;
    margin-bottom: 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

interface IOfferNFTModalProps {
  nft: any
  show: boolean
  onHide: () => void
}

function ChangePriceNFTModal(props: IOfferNFTModalProps): JSX.Element {
  const { nft, show, onHide } = props

  const account = useAccount()
  const currentNetwork = useCurrentNetwork()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()
  const topBidCallback = useTopBid(nft?.tokenId, currentNetwork?.contract?.Box)
  const bidsCallback = useBid(nft?.tokenId, currentNetwork?.contract?.Box)

  const [fee, setFee] = useState(0)
  const [sellAmount, setSellAmount] = useState('0')
  const [topBid, setTopBid] = useState<number | string>(0)
  const [totalBid, setTotalBid] = useState<number>(0)
  const [isDoneSell, setDoneSell] = useState<boolean>(false)

  const addTransaction = useTransactionAdder()
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const onChangePriceNFT = async () => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      let gasFee = 20000000000

      if (account && currentNetwork) {
        const _gasFee = await axios.get(
          `${currentNetwork.urlApi}/getfee?isDoneSell=${isDoneSell}&txType=change_price&totalBid=${totalBid}`,
        )
        if (_gasFee.status === 200 && _gasFee.data.gasFee) {
          // eslint-disable-next-line prefer-destructuring
          gasFee = _gasFee.data.gasFee
        }

        const value = new BigNumber(sellAmount).multipliedBy(1e9).toString()
        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, currentNetwork?.key ?? 'casper-test', 1, 1800000)
        const contractHashAsByteArray = decodeBase16(currentNetwork.contract.Market)
        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'change_price',
            RuntimeArgs.fromMap({
              minimum_offer: CLValueBuilder.u256(value),
              token_id: CLValueBuilder.string(nft?.tokenId),
            }),
          ),
          DeployUtil.standardPayment(gasFee),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(currentNetwork.rpcURL)

          let signature: any = undefined
          let deployObject: any = undefined

          if (connectorId === 'caspersigner' || connectorId === 'casperdash') {
            signature = await provider.sign(json, account, account)
            const _deploy = DeployUtil.deployFromJson(signature)
            deployObject = _deploy.val
          } else {
            signature = await provider.sign(JSON.stringify(json), account)
            deployObject = DeployUtil.setSignature(deploy, signature.signature, CLPublicKey.fromHex(account))
          }

          casperClient
            .putDeploy(deployObject)
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Change price #${nft.tokenId} to ${sellAmount} CSPR.`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
              onHide()
            })
            .catch((error: any) => {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              toast.error(error)
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  useEffect(() => {
    setFee((Number(currentNetwork?.marketplaceFee) * Number(sellAmount)) / 100)
  }, [sellAmount])

  // @ts-ignore
  useEffect(async () => {
    const response = await topBidCallback()
    const _bid = response ? priceBid(response) : '0'
    const _bids = await bidsCallback()
    setTopBid(_bid)
    setTotalBid(_bids?.length ? _bids.length : 0)
  }, [nft])

  useEffect(() => {
    if (Number(sellAmount) <= Number(topBid)) {
      setDoneSell(true)
    } else {
      setDoneSell(false)
    }
  }, [nft, topBid, sellAmount])

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  return (
    <>
      <StyledModal show={show} title="Change Price" onHide={onHide}>
        <ModalSubTitle>You must list above {currentNetwork?.minPrice} CSPR</ModalSubTitle>
        <InputWrapper>
          <InputField>
            <input value={sellAmount} onChange={e => setSellAmount(e.target.value)} />
            <label>CSPR</label>
          </InputField>
          <InfoRow>
            <p>Marketplace fee:</p>
            <p>{fee} CSPR</p>
          </InfoRow>
          <InfoRow>
            <p>Total offer amount:</p>
            <p>{sellAmount} CSPR</p>
          </InfoRow>
        </InputWrapper>
        <ButtonWrapper>
          <Button type="primary" loading={attemptingTxn} handleClick={onChangePriceNFT}>
            Change Price
          </Button>
        </ButtonWrapper>
      </StyledModal>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Change Price"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </>
  )
}

export default ChangePriceNFTModal
