import styled from 'styled-components/macro'
import DefaultNFTImage from 'assets/images/nft.png'
import { useEffect, useState } from 'react'
import { ipfsURLConvert } from 'utils'

const WrapTitle = styled.p`
  color: #565660;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 12px;
`

const OwnerWapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 3rem;
    margin-right: 12px;
    border-radius: 50%;
  }

  h6 {
    font-size: 14px;
    line-height: 18px;
    color: #191820;
    margin: 0;
  }
`

interface IDetails {
  owner: any
  metaData: any
  symbol: any
}

function DetailsTab(props: IDetails): JSX.Element {
  const { owner, metaData, symbol } = props

  const [ownerEllipsis, setOwnerEllipsis] = useState()

  useEffect(() => {
    if (owner) {
      const ownerSlice = owner.startsWith('account-hash-') ? owner.slice(13) : owner
      const _ownerEllipsis = ownerSlice
        ? `${ownerSlice.substring(0, 6)}...${ownerSlice.substring(ownerSlice.length - 4)}`
        : ownerSlice

      setOwnerEllipsis(_ownerEllipsis)
    }
  }, [owner])

  return (
    <>
      {owner && metaData && (
        <>
          <div className="d-flex justify-content-start align-items-center gap-5">
            <div>
              <WrapTitle>Current Owner</WrapTitle>
              <OwnerWapper>
                <img src={DefaultNFTImage} alt="" />
                <h6>{ownerEllipsis}</h6>
              </OwnerWapper>
            </div>
            <div>
              <WrapTitle>Creator</WrapTitle>
              <OwnerWapper>
                {metaData.map(
                  meta => meta.key === 'asset' && <img key={meta.key} src={ipfsURLConvert(meta.value)} alt="" />,
                )}
                <h6>{symbol}</h6>
              </OwnerWapper>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default DetailsTab
