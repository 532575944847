import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { CasperClient, CLByteArray, CLPublicKey, CLValueBuilder, DeployUtil, RuntimeArgs } from 'casper-js-sdk'
import { useTopBid, useCurrentNetwork, useSell, useBid } from 'hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import Modal from 'components/Modal'
import Button from 'components/Button'
import { createRecipientAddress } from 'casper-js-client-helper/dist/helpers/lib'
import axios from 'axios'
import { priceBid } from 'utils'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import BigNumber from 'bignumber.js'

const StyledModal = styled(Modal)`
  .modal-body {
    padding: 0 2.5rem 3.75rem;
  }
`

const InputWrapper = styled.div`
  margin: 4rem 0 2rem;
`

const ModalSubTitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  color: #191820;
  margin-bottom: 2rem;
  margin-top: 1rem;
  text-align: center;
`

const InputField = styled.fieldset`
  position: relative;
  margin-bottom: 1rem;

  label {
    position: absolute;
    font-weight: 100;
    font-size: 14px;
    line-height: 22px;
    right: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    border: 1px solid #e5e5e5;
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    border-radius: 24px;
    padding: 11px 21px;

    &:focus {
      outline: none !important;
      border-color: #b9b8bb;
    }
  }
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #565660;
    margin-bottom: 0;
  }
`

const WarningText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ad203e;
  margin-bottom: 10px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

interface IBidNFTModalProps {
  nft: any
  id?: string
  show: boolean
  currentBid?: string | undefined
  onHide: () => void
}

function IncreaseBidNFTModal(props: IBidNFTModalProps): JSX.Element {
  const { nft, id, show, onHide, currentBid } = props

  const account = useAccount()
  const currentNetwork = useCurrentNetwork()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()
  const topBidCallback = useTopBid(nft?.tokenId ? nft?.tokenId : id, currentNetwork?.contract.Box)
  const bidsCallback = useBid(nft?.tokenId ? nft?.tokenId : id, currentNetwork?.contract.Box)
  const sellCallback = useSell(nft?.tokenId ? nft?.tokenId : id)

  // const [fee, setFee] = useState<number>(8 / 100)
  const [topBid, setTopBid] = useState<number | string>()
  const [totalBid, setTotalBid] = useState<number>(0)
  const [bidAmount, setBidAmount] = useState('')
  const [increaseAmount, setIncreaseAmount] = useState<string | number>('')
  const [minimumOffer, setMinimumOffer] = useState<number>(0)
  const [isActive, setActive] = useState<boolean>(false)
  const [isWarning, setWarning] = useState<boolean>(false)
  const [isDoneSell, setDoneSell] = useState<boolean>(false)

  const addTransaction = useTransactionAdder()
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const onIncreaseBid = async () => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      let gasFee = 28000000000

      if (account && currentNetwork) {
        const _gasFee = await axios.get(
          `${currentNetwork.urlApi}/getfee?isDoneSell=${isDoneSell}&txType=increase_bid&totalBid=${totalBid}`,
        )
        if (_gasFee.status === 200 && _gasFee.data.gasFee) {
          // eslint-disable-next-line prefer-destructuring
          gasFee = _gasFee.data.gasFee
        }
        // const value = Number(increaseAmount) + fee
        const value = Number(increaseAmount)
        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, currentNetwork?.key ?? 'casper-test', 1, 1800000)
        const nftContractHash = new CLByteArray(Uint8Array.from(Buffer.from(currentNetwork?.contract?.Box, 'hex')))
        const nftMarketHash = new CLByteArray(Uint8Array.from(Buffer.from(currentNetwork.contract.Market, 'hex')))

        const runtimeArgs = RuntimeArgs.fromMap({
          deposit_entry_point_name: CLValueBuilder.string('increase_bid'),
          amount: CLValueBuilder.u512(value * 1e9),
          marketplace_hash: createRecipientAddress(nftMarketHash),
          nft_contract_hash: createRecipientAddress(nftContractHash),
          token_id: CLValueBuilder.string(nft?.tokenId ? nft.tokenId : id),
          bidder: createRecipientAddress(CLPublicKey.fromHex(account)),
        })

        const response = await axios.get('/payment_contract.wasm', {
          responseType: 'arraybuffer',
        })
        const instance = new Uint8Array(Buffer.from(response.data, 'binary'))

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newModuleBytes(instance, runtimeArgs),
          DeployUtil.standardPayment(gasFee),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(currentNetwork.rpcURL)

          let signature: any = undefined
          let deployObject: any = undefined

          if (connectorId === 'caspersigner' || connectorId === 'casperdash') {
            signature = await provider.sign(json, account, account)
            const _deploy = DeployUtil.deployFromJson(signature)
            deployObject = _deploy.val
          } else {
            signature = await provider.sign(JSON.stringify(json), account)
            deployObject = DeployUtil.setSignature(deploy, signature.signature, CLPublicKey.fromHex(account))
          }

          casperClient
            .putDeploy(deployObject)
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Increase bid #${nft?.tokenId ? nft.tokenId : id} to ${bidAmount}.`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
              onHide()
            })
            .catch((error: any) => {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              toast.error(error)
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  // @ts-ignore
  useEffect(async () => {
    const response = await topBidCallback()
    const _bid = response ? priceBid(response) : '0'
    setTopBid(_bid)
    const _bids = await bidsCallback()
    setTotalBid(_bids?.length ? _bids.length : 0)
  }, [nft, id])

  // @ts-ignore
  useEffect(async () => {
    const _sell = await sellCallback()
    if (_sell?.length > 0) {
      const _minimumOffer = new BigNumber(_sell[0]?.minimumOffer).div(1e9).toNumber()
      setMinimumOffer(_minimumOffer)
    }
  }, [nft, id])

  useEffect(() => {
    if (Number(topBid) < Number(bidAmount)) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [bidAmount, topBid])

  useEffect(() => {
    if (Number(bidAmount) >= minimumOffer) {
      setDoneSell(true)
    } else {
      setDoneSell(false)
    }
  }, [nft, bidAmount])

  useEffect(() => {
    const _biddingPrice = priceBid(nft?.biddingPrice)
    const _increaseAmount = Number(bidAmount) - Number(currentBid ? currentBid : _biddingPrice)
    if (_increaseAmount < 0) {
      setIncreaseAmount(0)
    } else {
      setIncreaseAmount(_increaseAmount)
    }
  }, [bidAmount])

  useEffect(() => {
    if (Number(bidAmount) > minimumOffer) {
      setWarning(true)
    } else {
      setWarning(false)
    }
  }, [bidAmount, minimumOffer])

  useEffect(() => {
    const _minimumOffer = new BigNumber(nft?.minimumOffer).div(1e9).toNumber()
    setMinimumOffer(_minimumOffer)
  }, [nft, id])

  // useEffect(() => {
  //   setFee((8 * Number(increaseAmount)) / 100)
  // }, [increaseAmount])

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  return (
    <>
      <StyledModal show={show} title={`Increase Bid`} onHide={onHide}>
        <ModalSubTitle>You must bid higher than {topBid} CSPR</ModalSubTitle>
        <InputWrapper>
          <InputField>
            <input value={bidAmount} onChange={e => setBidAmount(e.target.value)} />
            <label>CSPR</label>
          </InputField>
          {isWarning && <WarningText>This offer is above the current listing price ({minimumOffer} CSPR)</WarningText>}
          {/*<InfoRow>*/}
          {/*  <p>Adding service fee:</p>*/}
          {/*  <p>{fee} CSPR</p>*/}
          {/*</InfoRow>*/}
          <InfoRow>
            <p>Adding bid amount:</p>
            <p>{increaseAmount} CSPR</p>
          </InfoRow>
          <InfoRow>
            <p>Total bid amount:</p>
            <p>{bidAmount} CSPR</p>
          </InfoRow>
        </InputWrapper>
        <ButtonWrapper>
          <Button type="primary" loading={attemptingTxn} disabled={!isActive} handleClick={onIncreaseBid}>
            Increase Bid
          </Button>
        </ButtonWrapper>
      </StyledModal>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Increase Bid"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </>
  )
}

export default IncreaseBidNFTModal
