/* eslint-disable @typescript-eslint/no-extra-semi */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHasPendingApproval, useTransactionAdder } from 'state/transactions/hooks'
import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import { useCurrentNetwork } from './useNetwork'
import {
  CasperClient,
  CLByteArray,
  CLKey,
  CLBool,
  CLPublicKey,
  decodeBase16,
  DeployUtil,
  RuntimeArgs,
} from 'casper-js-sdk'
import { toast } from 'react-toastify'
import { CEP78Client } from 'casper-cep78-js-client'
import { createRecipientAddress } from 'casper-js-client-helper/dist/helpers/lib'

export enum NFTApprovalState {
  UNKNOWN = 'UNKNOWN',
  NOT_APPROVED = 'NOT_APPROVED',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
}

export const useApproveNFTCasperCallback = (
  contractHash: string,
  tokenIds: string[],
  spender: string,
): [NFTApprovalState, () => Promise<void>] => {
  const account = useAccount()

  const addTransaction = useTransactionAdder()
  const pendingApproval = useHasPendingApproval(contractHash, spender)
  const network = useCurrentNetwork()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()

  const [approved, setApproved] = useState(false)
  const [tokenName, setTokenName] = useState<any>()

  useEffect(() => {
    ;(async () => {
      try {
        if (network && contractHash && account && tokenIds) {
          const _cep78Client = await CEP78Client.createInstance(
            contractHash,
            network.rpcURL,
            network.key ?? 'casper-test',
          )

          const _tokenName = _cep78Client.collectionName()
          setTokenName(_tokenName)

          const _operator = await _cep78Client.checkOperatorDictionaryKey(account, spender)
          setApproved(_operator)
        }
      } catch (e) {
        setApproved(false)
        console.error(e)
      }
    })()
  }, [account, contractHash, pendingApproval])

  const approvalState: NFTApprovalState = useMemo(() => {
    if (!account) return NFTApprovalState.UNKNOWN
    return !approved
      ? pendingApproval
        ? NFTApprovalState.PENDING
        : NFTApprovalState.NOT_APPROVED
      : NFTApprovalState.APPROVED
  }, [account, contractHash, approved, spender, pendingApproval])

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== NFTApprovalState.NOT_APPROVED) {
      console.error('approve nft was called unnecessarily')
      return
    }

    if (!contractHash) {
      console.error('no contract')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    if (account && network) {
      try {
        const deployParams = new DeployUtil.DeployParams(
          CLPublicKey.fromHex(account),
          network?.key ?? 'casper-test',
          1,
          1800000,
        )

        const runtimeArgs = RuntimeArgs.fromMap({
          token_owner: createRecipientAddress(CLPublicKey.fromHex(account)),
          approve_all: new CLBool(true),
          operator: new CLKey(new CLByteArray(decodeBase16(spender))),
        })

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            decodeBase16(contractHash),
            'set_approval_for_all',
            runtimeArgs,
          ),
          DeployUtil.standardPayment(2000000000),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(network.rpcURL)

          let signature: any = undefined
          let deployObject: any = undefined

          if (connectorId === 'caspersigner' || connectorId === 'casperdash') {
            signature = await provider.sign(json, account, account)
            const _deploy = DeployUtil.deployFromJson(signature)
            deployObject = _deploy.val
          } else {
            signature = await provider.sign(JSON.stringify(json), account)
            deployObject = DeployUtil.setSignature(deploy, signature.signature, CLPublicKey.fromHex(account))
          }

          casperClient
            .putDeploy(deployObject)
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Approve ${tokenName ?? 'NFT'}`,
                approval: { tokenAddress: contractHash, spender: spender },
              })
            })
            .catch((error: any) => {
              console.error(error)
              toast.error(error)
            })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [NFTApprovalState, account, contractHash, spender, addTransaction])

  return [approvalState, approve]
}
