import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import {
  CasperClient,
  CLPublicKey,
  CLByteArray,
  CLValueBuilder,
  decodeBase16,
  DeployUtil,
  RuntimeArgs,
} from 'casper-js-sdk'
import { NFTApprovalState, useApproveNFTCasperCallback, useCurrentNetwork, useTopBid } from 'hooks'
import { useTransactionAdder } from 'state/transactions/hooks'

import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Loader from 'components/Loader'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import axios from 'axios/index'
import { priceBid } from '../../utils'

const StyledModal = styled(Modal)`
  .modal-body {
    padding: 0 2.5rem 3.75rem;
  }
`

const InputWrapper = styled.div`
  margin-bottom: 2rem;
`

const ModalSubTitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  color: #191820;
  margin-bottom: 2rem;
  margin-top: 1rem;
  text-align: center;
`

const InputField = styled.fieldset`
  position: relative;
  margin-bottom: 1rem;

  label {
    position: absolute;
    font-weight: 100;
    font-size: 14px;
    line-height: 22px;
    right: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    border: 1px solid #e5e5e5;
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    border-radius: 24px;
    padding: 11px 21px;

    &:focus {
      outline: none !important;
      border-color: #b9b8bb;
    }
  }
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #565660;
    margin-bottom: 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

interface IOfferNFTModalProps {
  nft: any
  id?: string
  totalBid: number
  show: boolean
  onHide: () => void
}

function SellNFTModal(props: IOfferNFTModalProps): JSX.Element {
  const { nft, show, onHide, totalBid, id } = props

  const account = useAccount()
  const currentNetwork = useCurrentNetwork()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()
  const bidCallback = useTopBid(nft?.tokenId ? nft?.tokenId : id, currentNetwork?.contract?.NFT)

  const [fee, setFee] = useState(0)
  const [sellAmount, setSellAmount] = useState('0')
  const [topBid, setTopBid] = useState<number | string>(0)
  const [isActive, setActive] = useState<boolean>(false)
  const [isDoneSell, setDoneSell] = useState<boolean>(false)

  const [approvalNFT, approveNFTCallback] = useApproveNFTCasperCallback(
    currentNetwork?.contract.Box,
    [nft?.token_id],
    currentNetwork?.contract.Market,
  )

  const addTransaction = useTransactionAdder()
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const onSellNFT = async () => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      let gasFee = 30000000000

      if (account && currentNetwork) {
        const _gasFee = await axios.get(
          `${currentNetwork.urlApi}/getfee?isDoneSell=${isDoneSell}&txType=sell&totalBid=${totalBid}`,
        )
        if (_gasFee.status === 200 && _gasFee.data.gasFee) {
          // eslint-disable-next-line prefer-destructuring
          gasFee = _gasFee.data.gasFee
        }
        const value = Number(sellAmount) * 1e9
        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, currentNetwork?.key ?? 'casper-test', 1, 1800000)
        const contractHashAsByteArray = decodeBase16(currentNetwork.contract.Market)
        const nftContractHash = new CLByteArray(Uint8Array.from(Buffer.from(currentNetwork.contract.Box, 'hex')))

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'sell',
            RuntimeArgs.fromMap({
              token_id: CLValueBuilder.string(nft.token_id),
              nft_contract_hash: CLValueBuilder.key(nftContractHash),
              minimum_offer: CLValueBuilder.u256(value),
            }),
          ),
          DeployUtil.standardPayment(gasFee),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(currentNetwork.rpcURL)

          let signature: any = undefined
          let deployObject: any = undefined
          if (connectorId === 'caspersigner' || connectorId === 'casperdash') {
            signature = await provider.sign(json, account, account)
            const _deploy = DeployUtil.deployFromJson(signature)
            deployObject = _deploy.val
          } else {
            signature = await provider.sign(JSON.stringify(json), account)
            deployObject = DeployUtil.setSignature(deploy, signature.signature, CLPublicKey.fromHex(account))
          }

          casperClient
            .putDeploy(deployObject)
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Sell ${sellAmount} CSPR #${nft.token_id}.`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
              onHide()
            })
            .catch((error: any) => {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              toast.error(error)
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  // @ts-ignore
  useEffect(async () => {
    const response = await bidCallback()
    const _bid = response ? priceBid(response) : currentNetwork?.minPrice
    setTopBid(_bid ? _bid : 0)
  }, [nft, id])

  // @ts-ignore
  useEffect(async () => {
    if (Number(sellAmount) <= Number(topBid)) {
      setDoneSell(true)
    } else {
      setDoneSell(false)
    }
  }, [nft, topBid])

  useEffect(() => {
    Number(sellAmount) >= Number(currentNetwork?.minPrice) ? setActive(true) : setActive(false)
    setFee((Number(currentNetwork?.marketplaceFee) * Number(sellAmount)) / 100)
  }, [sellAmount])

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  return (
    <>
      <StyledModal show={show} title="Sell Digital Collectibles" onHide={onHide}>
        <ModalSubTitle>You must list above {currentNetwork?.minPrice} CSPR</ModalSubTitle>
        <InputWrapper>
          <InputField>
            <input value={sellAmount} onChange={e => setSellAmount(e.target.value)} />
            <label>CSPR</label>
          </InputField>
          <InfoRow>
            <p>Marketplace fee:</p>
            <p>{fee} CSPR</p>
          </InfoRow>
          <InfoRow>
            <p>Total offer amount:</p>
            <p>{sellAmount} CSPR</p>
          </InfoRow>
        </InputWrapper>
        <ButtonWrapper>
          {approvalNFT == NFTApprovalState.NOT_APPROVED || approvalNFT == NFTApprovalState.PENDING ? (
            <Button type="primary" handleClick={approveNFTCallback}>
              {approvalNFT == NFTApprovalState.PENDING ? (
                <>
                  <Loader /> Approving #{nft.token_id}
                </>
              ) : (
                'Approve'
              )}
            </Button>
          ) : (
            <Button type="primary" loading={attemptingTxn} handleClick={onSellNFT} disabled={!isActive}>
              Sell
            </Button>
          )}
        </ButtonWrapper>
      </StyledModal>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Sell NFT"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </>
  )
}

export default SellNFTModal
