import styled from 'styled-components/macro'

const StyledTitle = styled.h4<{ align?: string; color: string; mb?: boolean }>`
  text-align: ${({ align }) => (align == 'center' ? 'center' : 'left')};
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  margin-bottom: ${({ mb }) => (mb === true ? '24px' : '8px')};
  color: ${({ color }) => (color == 'light' ? '#fff' : '#000')};
`

interface ITitleProps {
  align?: 'center'
  mb?: boolean
  color: 'light' | 'dark'
  children: React.ReactNode
}

function SectionTitle(props: ITitleProps): JSX.Element {
  const { align, color, children, mb } = props

  return (
    <StyledTitle align={align} color={color} mb={mb}>
      {children}
    </StyledTitle>
  )
}

export default SectionTitle
