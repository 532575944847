import { useState, useEffect, useCallback } from 'react'
import { Container, Tab, Row, Col } from 'react-bootstrap'
import styled from 'styled-components/macro'
import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import { useBids, useCurrentNetwork, useMetadata, useNFTs, useSells } from 'hooks'
import SectionTitle from 'components/SectionTitle'
import Loader from 'components/Loader'
import { NFT2 } from 'type/NFT'
import NFTBox from './NFTBox'
import StyledTab from 'components/Tab'
import NFTItem from 'components/NFTItem'
import Button from 'components/Button'
// import BidNFTModal from 'components/BidNFTModal'
import {
  CasperClient,
  CLByteArray,
  CLPublicKey,
  CLValueBuilder,
  decodeBase16,
  DeployUtil,
  RuntimeArgs,
} from 'casper-js-sdk'
import { toast } from 'react-toastify'
import { useTransactionAdder } from 'state/transactions/hooks'
import IncreaseBidNFTModal from 'components/IncreaseBidNFTModal'
import ChangePriceNFTModal from 'components/ChangePriceNFTModal'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import Layout from 'components/Layout'
import { CiWallet } from 'react-icons/ci'
import ConnectModal from '../../components/ConnectButton/ConnectModal'

const SectionWrapper = styled.div`
  padding: 5rem 0;

  @media (min-width: 992px) {
    padding: 2.5rem 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  margin-top: 5rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    width: 100%;
  }
`

function MyBoxes(): JSX.Element {
  const [nfts, setNFTs] = useState<NFT2[]>([])
  const [bids, setBids] = useState<any>([])
  const [sells, setSells] = useState<any>([])
  const [metadata, setMetada] = useState([])
  const [loading, setLoading] = useState(false)
  const [isFetchingSells, setFetchingSells] = useState(false)
  const [isFetchingBids, setFetchingBids] = useState(false)
  const [currentNFT, setCurrentNFT] = useState<any>()
  const [showBidModal, setShowBidModal] = useState(false)
  const [showChangePriceModal, setShowChangePriceModal] = useState(false)
  const [showConnectModal, setShowConnectModal] = useState(false)

  const addTransaction = useTransactionAdder()
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')
  const [title, setTitle] = useState('')
  const [accountHash, setAccountHash] = useState<string>('')

  const account = useAccount()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()

  const currentNetwork = useCurrentNetwork()
  const nftsCallback = useNFTs(accountHash)
  const bidsCallback = useBids(account)
  const sellsCallback = useSells(account)

  const onChooseBid = (nft: any) => {
    setShowBidModal(true)
    setCurrentNFT(nft)
  }

  const onChooseChangePrice = (nft: any) => {
    setShowChangePriceModal(true)
    setCurrentNFT(nft)
  }

  const onCancelSell = async (nft: any) => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      setTitle('Cancel Sell')

      if (account && currentNetwork) {
        // @ts-ignore
        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, currentNetwork?.key ?? 'casper-test', 1, 1800000)
        // @ts-ignore
        const contractHashAsByteArray = decodeBase16(currentNetwork.contract.Market)
        const nftContractHash = new CLByteArray(Uint8Array.from(Buffer.from(currentNetwork.contract.Box, 'hex')))
        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'revoke_sell',
            RuntimeArgs.fromMap({
              token_id: CLValueBuilder.string(nft.tokenId),
              nft_contract_hash: CLValueBuilder.key(nftContractHash),
            }),
          ),
          DeployUtil.standardPayment(25000000000),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          // @ts-ignore
          const casperClient = new CasperClient(currentNetwork.rpcURL)

          let signature: any = undefined
          let deployObject: any = undefined
          if (connectorId === 'caspersigner' || connectorId === 'casperdash') {
            signature = await provider.sign(json, account, account)
            const _deploy = DeployUtil.deployFromJson(signature)
            deployObject = _deploy.val
          } else {
            signature = await provider.sign(JSON.stringify(json), account)
            // @ts-ignore
            deployObject = DeployUtil.setSignature(deploy, signature.signature, CLPublicKey.fromHex(account))
          }

          casperClient
            .putDeploy(deployObject)
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Cancel sell #${nft.tokenId}.`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
            })
            .catch((error: any) => {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              toast.error(error)
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  const onCancelBid = async (nft: any) => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      setTitle('Cancel Bid')

      if (account && currentNetwork) {
        // @ts-ignore
        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, currentNetwork?.key ?? 'casper-test', 1, 1800000)
        // @ts-ignore
        const contractHashAsByteArray = decodeBase16(currentNetwork.contract.Market)
        const nftContractHash = new CLByteArray(Uint8Array.from(Buffer.from(nft.nftContract, 'hex')))
        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'revoke_bid',
            RuntimeArgs.fromMap({
              token_id: CLValueBuilder.string(nft.tokenId),
              nft_contract_hash: CLValueBuilder.key(nftContractHash),
            }),
          ),
          DeployUtil.standardPayment(10000000000),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          // @ts-ignore
          const casperClient = new CasperClient(currentNetwork.rpcURL)

          let signature: any = undefined
          let deployObject: any = undefined

          if (connectorId === 'caspersigner' || connectorId === 'casperdash') {
            signature = await provider.sign(json, account, account)
            const _deploy = DeployUtil.deployFromJson(signature)
            deployObject = _deploy.val
          } else {
            signature = await provider.sign(JSON.stringify(json), account)
            // @ts-ignore
            deployObject = DeployUtil.setSignature(deploy, signature.signature, CLPublicKey.fromHex(account))
          }

          casperClient
            .putDeploy(deployObject)
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Cancel bid #${nft.tokenId}.`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
            })
            .catch((error: any) => {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              toast.error(error)
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  const fetchNFTs = async () => {
    setLoading(true)
    const _nfts = await nftsCallback()
    setNFTs(_nfts)
    setLoading(false)
  }

  const getBids = async () => {
    try {
      setFetchingBids(true)
      const _bids = await bidsCallback()

      if (_bids?.length > 0) {
        let result = [
          ...[..._bids]
            .reduce((acc, curr) => acc.set(curr.tokenId, { ...acc.get(curr.tokenId), ...curr }), new Map())
            .values(),
        ]
        result = result.filter(e => e.biddingPrice !== undefined)
        setBids(result)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setFetchingBids(false)
    }
  }

  const getSells = async () => {
    try {
      setFetchingSells(true)
      const _sells = await sellsCallback()

      if (_sells?.length > 0) {
        let result = [
          ...[..._sells]
            .reduce((acc, curr) => acc.set(curr.tokenId, { ...acc.get(curr.tokenId), ...curr }), new Map())
            .values(),
        ]
        result = result.filter(e => e.minimumOffer !== undefined)
        setSells(result)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setFetchingSells(false)
    }
  }

  const getAccountHash = async () => {
    if (account) {
      const _account = CLPublicKey.fromHex(account).toAccountHashStr()
      const _splitAccountHex = _account.split('-')
      setAccountHash(_splitAccountHex[2])
    }
  }

  useEffect(() => {
    getAccountHash()
  }, [account])

  useEffect(() => {
    fetchNFTs()
  }, [accountHash])

  useEffect(() => {
    getSells()
    getBids()
    const interval = setInterval(() => {
      getSells()
      getBids()
    }, 1000 * 45)

    return () => clearInterval(interval)
  }, [account, metadata])

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  return (
    <Layout>
      <SectionWrapper>
        <Container>
          <SectionTitle color="dark" mb={true}>
            Portfolio
          </SectionTitle>

          {account ? (
            <>
              <StyledTab defaultActiveKey="myBox">
                <Tab eventKey="myBox" title="My Boxes">
                  {loading ? (
                    <ContentWrapper>
                      <Loader size="40px" />
                    </ContentWrapper>
                  ) : (
                    <>
                      {nfts?.length > 0 ? (
                        <Row>
                          {nfts?.map((nft, index) => (
                            <Col key={index} xs={12} sm={6} lg={4} xl={3}>
                              <NFTBox nft={nft} />
                            </Col>
                          ))}
                        </Row>
                      ) : (
                        <ContentWrapper>No Digital Collectible found.</ContentWrapper>
                      )}
                    </>
                  )}
                </Tab>
                <Tab title="Sells" eventKey="sells">
                  <ContentWrapper>Coming soon</ContentWrapper>
                  {/* {sells?.length > 0 ? (
                    <Row>
                      {sells?.map((nft, index) => (
                        <Col key={index} xs={12} sm={6} lg={4} xl={3}>
                          <NFTItem
                            nft={nft}
                            children={
                              <ButtonWrapper onClick={e => e.stopPropagation()}>
                                <Button
                                  type="primary"
                                  loading={attemptingTxn}
                                  handleClick={() => onChooseChangePrice(nft)}
                                >
                                  Change Price
                                </Button>
                                <Button type="outline" loading={attemptingTxn} handleClick={() => onCancelSell(nft)}>
                                  Cancel Sell
                                </Button>
                              </ButtonWrapper>
                            }
                          />
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <>
                      {isFetchingSells ? (
                        <ContentWrapper>
                          <Loader size="40px" />
                        </ContentWrapper>
                      ) : (
                        <ContentWrapper>No selling Digital Collectible found.</ContentWrapper>
                      )}
                    </>
                  )} */}
                </Tab>
                <Tab title="Bids" eventKey="bids">
                  <ContentWrapper>Coming soon</ContentWrapper>
                  {/* {bids?.length > 0 ? (
                    <Row>
                      {bids?.map((nft, index) => (
                        <Col key={index} xs={12} sm={6} lg={4} xl={3}>
                          <NFTItem
                            nft={nft}
                            children={
                              <ButtonWrapper onClick={e => e.stopPropagation()}>
                                <Button type="primary" loading={attemptingTxn} handleClick={() => onChooseBid(nft)}>
                                  Increase Bid
                                </Button>
                                <Button type="outline" loading={attemptingTxn} handleClick={() => onCancelBid(nft)}>
                                  Cancel Bid
                                </Button>
                              </ButtonWrapper>
                            }
                          />
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <>
                      {isFetchingBids ? (
                        <ContentWrapper>
                          <Loader size="40px" />
                        </ContentWrapper>
                      ) : (
                        <ContentWrapper>No bidding Digital Collectible found.</ContentWrapper>
                      )}
                    </>
                  )} */}
                </Tab>
              </StyledTab>
              <IncreaseBidNFTModal nft={currentNFT} show={showBidModal} onHide={() => setShowBidModal(false)} />
              <ChangePriceNFTModal
                nft={currentNFT}
                show={showChangePriceModal}
                onHide={() => setShowChangePriceModal(false)}
              />
              <TransactionConfirmationModal
                isOpen={showConfirm}
                title={title}
                attemptingTxn={attemptingTxn}
                hash={txHash}
                pendingText=""
                onDismiss={handleDismissConfirmation}
                content={() => <></>}
              />
            </>
          ) : (
            <>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="primary" handleClick={() => setShowConnectModal(true)}>
                  <CiWallet size={20} />
                  Connect Wallet
                </Button>
              </div>
              <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
            </>
          )}
        </Container>
      </SectionWrapper>
    </Layout>
  )
}

export default MyBoxes
