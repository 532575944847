import { useCallback } from 'react'
import axios from 'axios'

import { CLPublicKey } from 'casper-js-sdk'
import { useCurrentNetwork } from './useNetwork'

export const useSells = (account: string | undefined | null): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      if (account) {
        const _accountHash = CLPublicKey.fromHex(account).toAccountHashStr()
        const accountHash = _accountHash.split('-')[2]
        const response = await axios.get(`${currentNetwork?.urlApi}/getsells?offeror=${accountHash}&isActive=true`)
        if (response.status === 200 && response.data.sells) {
          return response.data.sells
        }
      }
    } catch (error: any) {
      console.error(error)
      return []
    }
  }, [account])
}

export const useSell = (tokenId: string | undefined | null): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      if (tokenId) {
        const response = await axios.get(`${currentNetwork?.urlApi}/getsells?tokenId=${tokenId}`)
        if (response.status === 200 && response.data.sells) {
          return response.data.sells
        }
      }
    } catch (error: any) {
      console.error(error)
      return []
    }
  }, [tokenId])
}
