import { useCallback } from 'react'
import axios from 'axios'
import { useCurrentNetwork } from './useNetwork'

export const useMarketplace = (isActive = true): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      let url
      if (isActive) {
        url = `${currentNetwork?.urlApi}/getsells/?isActive=${isActive}`
      } else {
        url = `${currentNetwork?.urlApi}/getsells/`
      }
      const response = await axios.get(url)

      if (response.status === 200 && response.data) {
        return response.data.sells
      }
    } catch (error: any) {
      console.error(error)
      return []
    }
  }, [])
}
