import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { CiWallet } from 'react-icons/ci'

export const HeaderMain = styled.header`
  position: fixed;
  background-color: #fff;
  top: 0;
  width: 100%;
  z-index: 9;

  @media (min-width: 992px) {
    position: relative;
    background-color: transparent;
  }
`

export const HeaderWrapper = styled.div`
  position: relative;
  z-index: 9;
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;

  @media (min-width: 992px) {
    display: flex;
  }
`

export const HeaderMobile = styled.div`
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  @media (min-width: 992px) {
    display: none;
  }
`

export const MobileLogo = styled.img`
  max-width: 100%;
`

export const TitleWapper = styled.div`
  position: relative;
  background: #161f2f;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 2rem 0 1.75rem;

  span {
    font-weight: 700;
    font-size: 23px;
    line-height: 28px;
    color: #fff;
    text-transform: uppercase;
  }

  @media (min-width: 1200px) {
    padding: 0 2rem 0 5.5rem;
  }

  @media (min-width: 1400px) {
    padding: 0 2rem 0 6.25rem;
  }

  @media (min-width: 1920px) {
    padding: 0 2rem 0 19.5rem;
  }

  @media (min-width: 2560px) {
    padding: 0 2rem 0 39rem;
  }
`

export const NavBarWrap = styled.div`
  background: #ad203e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  padding-left: 2rem;

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    justify-content: left;
    width: fit-content;
    margin-bottom: 0;

    li {
      margin: 1rem;
    }
  }

  @media (min-width: 992px) {
    padding-right: 5rem;
  }

  @media (min-width: 1400px) {
    padding-right: 10rem;
  }

  @media (min-width: 1920px) {
    padding-right: 15rem;
  }

  @media (min-width: 2560px) {
    padding-right: 20rem;
  }
`

export const MenuTab = styled.select`
  background: transparent;
  border: none;
`

export const NavLink = styled(Link)`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #161f2f;
    transition: all 0.5s ease;
    cursor: pointer;
  }
`

export const NavLink2 = styled.a`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  text-decoration: none;

  &:hover {
    color: #161f2f;
    transition: all 0.5s ease;
    cursor: pointer;
  }
`

export const MenuToggle = styled.button`
  background: transparent;
  border: none;

  @media (min-width: 992px) {
    display: none;
  }
`

export const ButtonWrapper = styled.div`
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
`

export const StyledWalletIcon = styled(CiWallet)`
  @media (min-width: 992px) {
    display: none;
  }
`
