import { useCallback } from 'react'
import axios from 'axios'
import { useCurrentNetwork } from './useNetwork'

export const useMetadata = (): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      const response = await axios.get(`${currentNetwork?.urlApi}/gettokens`)

      if (response.status === 200 && response.data.tokens) {
        return response.data.tokens
      }
    } catch (error: any) {
      console.error(error)
      return []
    }
  }, [])
}

export const useNFTMetadata = (tokenId: string): (() => Promise<any>) => {
  const currentNetwork = useCurrentNetwork()
  return useCallback(async (): Promise<any> => {
    try {
      const response = await axios.get(`${currentNetwork?.urlApi}/gettokens`)

      if (response.status === 200 && response.data.tokens) {
        const { tokens } = response.data
        const _metadata = tokens.filter(token => token.tokenId === tokenId)
        return _metadata[0]
      }
    } catch (error: any) {
      console.error(error)
      return {}
    }
  }, [])
}
