import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import Creator from 'assets/images/creator.png'
import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import { priceBid, trimName } from 'utils'
import moment from 'moment/moment'
import { useCurrentNetwork, useSell } from 'hooks'
import Button from '../../components/Button'
import BigNumber from 'bignumber.js'
import TransactionConfirmationModal from '../../components/TransactionConfirmationModal'
import {
  CasperClient,
  CLAccountHash,
  CLPublicKey,
  CLValueBuilder,
  decodeBase16,
  DeployUtil,
  RuntimeArgs,
} from 'casper-js-sdk'
import { toast } from 'react-toastify'
import { useTransactionAdder } from '../../state/transactions/hooks'

const ContentWrapper = styled.div`
  position: relative;
`

const BidWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #565660;
  margin-bottom: 1rem;

  img {
    margin-right: 10px;
  }

  span,
  a {
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: #191820;
  }

  a {
    text-decoration: underline;
  }
`

const Text = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
`

interface IBids {
  bidHistory: any
  id: string
  accountHash?: string | undefined
}

function BidsTab(props: IBids): JSX.Element {
  const { id, accountHash, bidHistory } = props
  const account = useAccount()
  const currentNetwork = useCurrentNetwork()
  const sellCallback = useSell(id)
  const addTransaction = useTransactionAdder()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()

  const [tokenOwner, setTokenOwner] = useState<string>('')

  const [isActive, setActive] = useState<boolean>(false)

  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const onChangePriceNFT = async (acceptBidPrice: string, bidder: string) => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)

      if (account && currentNetwork) {
        const senderKey = CLPublicKey.fromHex(account)
        const ownerAccountHashByte = Uint8Array.from(Buffer.from(bidder, 'hex'))

        const bidderKey = CLValueBuilder.key(new CLAccountHash(ownerAccountHashByte))
        // console.log('bidderKey', bidderKey)
        const deployParams = new DeployUtil.DeployParams(senderKey, currentNetwork?.key ?? 'casper-test', 1, 1800000)
        const contractHashAsByteArray = decodeBase16(currentNetwork.contract.Market)
        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'accept_price',
            RuntimeArgs.fromMap({
              token_id: CLValueBuilder.string(id),
              accepted_price: CLValueBuilder.u256(acceptBidPrice),
              accepted_bidder: bidderKey,
            }),
          ),
          DeployUtil.standardPayment(30000000000),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(currentNetwork.rpcURL)

          let signature: any = undefined
          let deployObject: any = undefined

          if (connectorId === 'caspersigner' || connectorId === 'casperdash') {
            signature = await provider.sign(json, account, account)
            const _deploy = DeployUtil.deployFromJson(signature)
            deployObject = _deploy.val
          } else {
            signature = await provider.sign(JSON.stringify(json), account)
            deployObject = DeployUtil.setSignature(deploy, signature.signature, CLPublicKey.fromHex(account))
          }

          casperClient
            .putDeploy(deployObject)
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Accept bid #${id} with ${new BigNumber(acceptBidPrice).div(1e9).toString()} CSPR.`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
              // onHide()
            })
            .catch((error: any) => {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              toast.error(error)
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  // @ts-ignore
  useEffect(async () => {
    const _sell = await sellCallback()
    if (_sell?.length > 0) {
      setTokenOwner(_sell[0]?.offeror)
      setActive(_sell[0].isActive)
    }
  }, [id])

  return (
    <ContentWrapper>
      {bidHistory?.length > 0 ? (
        <>
          {bidHistory?.map((bid, index) => (
            <BidWrapper key={index}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={Creator} alt="" />
                <div>
                  <span>{priceBid(bid?.biddingPrice)}</span> CSPR by{' '}
                  <a href={`${currentNetwork?.explorer}/account/${bid?.bidder}`} target="_blank">
                    {trimName(bid?.bidder, 5, 6, 20)}
                  </a>
                  <br />
                  {moment.utc(bid?.timestamps).local().format('YYYY-MM-DD HH:mm:ss')}
                </div>
              </div>
              {tokenOwner === accountHash && accountHash !== undefined && isActive && (
                <Button type="primary" handleClick={() => onChangePriceNFT(bid?.biddingPrice, bid?.bidder)}>
                  <span style={{ color: '#ffffff' }}>Accept</span>
                </Button>
              )}
            </BidWrapper>
          ))}
        </>
      ) : (
        <Text>No events have occurred yet.</Text>
      )}
      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Accept Bid"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </ContentWrapper>
  )
}

export default BidsTab
